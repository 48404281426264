import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IBusinnesType, IPharmacy } from 'types/DTO/pharmacies'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  pharmacies: [] as IPharmacy[],
  currentPharmacy: {} as IPharmacy,
  pharmaciesPageSeo: {},
  pharmaciesPageSeoText: '',
  pharmacyBySlug: {} as IPharmacy,
  meta: {} as IMetaData,
  foundPharmacies: [] as IPharmacy[],
  pharmBusinnessTypes: [] as IBusinnesType[],
  pharmWorkTimes: [
    { id: 0, title: 'Время работы', key: null },
    { id: 1, title: 'Открыто', key: 'open,all_day' },
    { id: 2, title: 'Круглосуточно', key: 'all_day' },
  ],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setPharmacies(state, payload) {
    state.pharmacies = payload
  },

  setCurrentPharmacy(state, payload) {
    state.currentPharmacy = payload
  },

  setPharmBusinnessTypes(state, payload) {
    state.pharmBusinnessTypes = payload
  },

  setPharmacyBySlug(state, payload) {
    state.pharmacyBySlug = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
  setFoundPharmacies(state, payload) {
    state.foundPharmacies = payload
  },
  clearFoundPharmacies(state) {
    state.foundPharmacies = []
  },
  setPharmaciesPageSeo(state, payload) {
    state.pharmaciesPageSeo = payload
  },
  setPharmaciesPageSeoText(state, payload) {
    state.pharmaciesPageSeoText = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getPharmacies({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.pharmacies.findAll(params)
      commit('setPharmacies', res.data.data)
      // @ts-ignore
      commit('setMeta', res.data.meta)
    } catch {
      console.log('error: getPharmacies')
    }
  },

  async getPharmaciesById({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.pharmacies.findAll(params)
      commit('setCurrentPharmacy', res.data.data[0])
      // @ts-ignore
      commit('setMeta', res.data.meta)
    } catch {
      console.log('error: getPharmacies')
    }
  },

  async getPharmBusinnessTypes({ commit }) {
    try {
      const res = await this.$repositories.pharmacies.getPharmBusinnessTypes()
      commit('setPharmBusinnessTypes', res.data.data)
    } catch {
      console.log('error: getPharmBusinnesTypes')
    }
  },

  async getPharmacyBySlug({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.pharmacies.findBySlug(slug, params)
      commit('setPharmacyBySlug', res.data)
    } catch {
      console.log('error: getPharmacyBySlug')
    }
  },

  async getPharmaciesByQuery({ commit }, { query, params }) {
    try {
      const res = await this.$repositories.pharmacies.findByQuery(query, params)
      commit('setFoundPharmacies', res.data)
    } catch {
      console.log('error: getPharmaciesByQuery')
    }
  },

  async getPharmaciesPage({ commit }) {
    try {
      const seoRes = await this.$repositories.content.findSeo('seo_pharmacies')
      commit('setPharmaciesPageSeo', seoRes?.data)
      commit('setPharmaciesPageSeoText', seoRes?.data.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  pharmacies(state) {
    return state.pharmacies
  },

  currentPharmacy(state) {
    return state.currentPharmacy
  },

  pharmBusinnessTypes(state) {
    return state.pharmBusinnessTypes
  },

  pharmWorkTimes(state) {
    return state.pharmWorkTimes
  },

  pharmacyBySlug(state) {
    return state.pharmacyBySlug
  },
  meta(state) {
    return state.meta
  },
  foundPharmacies(state) {
    return state.foundPharmacies
  },
  pharmaciesPageSeo(state) {
    return state.pharmaciesPageSeo
  },
  pharmaciesPageSeoText(state) {
    return state.pharmaciesPageSeoText
  },
}
