import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IContact, IContactDetails } from '~/types/DTO/contacts'

export const state = () => ({
  cities: [] as IContact[],
  details: [] as IContactDetails[],
  contactPageSeo: {},
  contactPageSeoText: '',
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setCity(state, payload) {
    state.cities = payload
  },
  setDetails(state, payload) {
    state.details = payload
  },

  setContactPageSeo(state, payload) {
    state.contactPageSeo = payload
  },

  setContactPageSeoText(state, payload) {
    state.contactPageSeoText = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getContactPage({ commit }) {
    try {
      const seoRes = await this.$repositories.content.findSeo('seo_contacts')
      commit('setContactPageSeo', seoRes?.data)
      commit('setContactPageSeoText', seoRes?.data.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },

  async getCities({ commit }, params: IHttpParams) {
    try {
      const { data } = await this.$repositories.contacts.findAll(params)
      const res = data.data

      commit('setCity', res)
    } catch (e) {
      console.log('error in getCities', e)

      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла ошибка при загрузке городов',
      })
    }
  },

  async getDetails({ commit }, { field, params }) {
    try {
      const data = await this.$repositories.contacts.findContactsDetails(field, params)
      /* TODO: убрать значения по умолчанию в сервисе, когда нужнен будет список */
      const res = this.$services.contacts.mapAddressDetails(data)

      commit('setDetails', res)
    } catch (e) {
      console.log('error in getDetails', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  cities(state) {
    return state.cities
  },
  details(state) {
    return state.details
  },

  contactPageSeo(state) {
    return state.contactPageSeo
  },

  contactPageSeoText(state) {
    return state.contactPageSeoText
  },
}
