import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IInstitution, IInstitutionType } from 'types/DTO/institutions'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  institutions: [] as IInstitution[],
  institutionsPageSeo: {},
  institutionsPageSeoText: '',
  institutionBySlug: {} as IInstitution,
  institutionTypes: [] as IInstitutionType[],
  meta: {} as IMetaData,
  foundInstitutions: [] as IInstitution[],
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setInstitutions(state, payload) {
    state.institutions = payload
  },
  setInstitutionBySlug(state, payload) {
    state.institutionBySlug = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
  setFoundInstitutions(state, payload) {
    state.foundInstitutions = payload
  },
  clearFoundInstitutions(state) {
    state.foundInstitutions = []
  },
  setInstitutionsPageSeo(state, payload) {
    state.institutionsPageSeo = payload
  },
  setInstitutionsPageSeoText(state, payload) {
    state.institutionsPageSeoText = payload
  },
  setInstitutionTypes(state, payload) {
    state.institutionTypes = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getInstitutions({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.institutions.findAll(params)
      console.log('institutions')
      commit('setInstitutions', res.data.data)
      // @ts-ignore
      commit('setMeta', res.data.meta)
    } catch {
      console.log('error: getInstitutions')
    }
  },

  async getInstitutionBySlug({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.institutions.findBySlug(slug, params)
      commit('setInstitutionBySlug', res.data)
    } catch {
      console.log('error: getInstitutionBySlug')
    }
  },

  async getInstitutionTypes({ commit }, params) {
    try {
      const res = await this.$repositories.institutions.findTypes(params)
      // @ts-ignore
      commit('setInstitutionTypes', res.data.data)
    } catch (e) {
      console.error('Error: getInstitutionTypes', e)
    }
  },

  async getInstitutionsPage({ commit }) {
    try {
      const seoRes = await this.$repositories.content.findSeo('seo_medical')
      commit('setInstitutionsPageSeo', seoRes?.data)
      commit('setInstitutionsPageSeoText', seoRes?.data.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  institutions(state) {
    return state.institutions
  },
  institutionBySlug(state) {
    return state.institutionBySlug
  },
  meta(state) {
    return state.meta
  },
  foundInstitutions(state) {
    return state.foundInstitutions
  },
  institutionsPageSeo(state) {
    return state.institutionsPageSeo
  },
  institutionsPageSeoText(state) {
    return state.institutionsPageSeoText
  },
  institutionTypes(state) {
    return state.institutionTypes
  },
}
